/* App.css */

/* Updated App styles to take full width */
.App {
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App-header {
  padding: 0px;
  position: relative;
  width: 100%;
  height: 40px; /* Added specific height */
}

/* Updated navigation styles to match mockup */
nav {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 20px;
  z-index: 100;
}

.App-link {
  color: white;
  background: none;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: opacity 0.3s;
  font-family: Arial, sans-serif;
  position: relative;
}

.App-link:hover {
  opacity: 0.8;
  background: none;
  color: white;
}

.App-link.active {
  color: white;
}

.App-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 70%;
  height: 2px;
  background-color: white;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  background-color: #000000;
}

/* General styles that can be used across the application */

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
}

/* Form styles */
input, select, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #61dafb;
  background-color: #282c34;
  color: white;
  border-radius: 4px;
  font-size: 16px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #61dafb;
}

/* Error messages */
.error-message {
  color: #ff6b6b;
  margin-top: 5px;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #282c34;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

/* Reset any potential body margins */
body {
  margin: 0;
  padding: 0;
  background-color: #000000;
}

/* Reusable logo style */
.sds-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 300px; /* Adjust as needed */
  height: auto;
  z-index: 100; /* Ensure it's on top */
}

.sds-logo img {
  max-width: 100%;
  height: auto;
}
