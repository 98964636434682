.calendar-container {
  max-width: 900px;
  width: 100%;
  background-color: #333;
  color: #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 120px auto 20px;
  padding: 20px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}

.calendar-day-header {
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background-color: #555;
  border-bottom: 1px solid #e0e0e0;
}

.calendar-day {
  background-color: #666;
  padding: 8px 8px 12px 8px;
  min-height: 66px;
  border: 1px solid #555;
  overflow: auto;
}

.day-number {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 0.9rem;
  color: #ccc;
}

.outside-month {
  background-color: #555;
}

.outside-month .day-number {
  color: #888;
}

.outside-month .event {
  background-color: #a3c2e6;
}

.outside-month .event.all-day {
  background-color: #8ab3e6;
}

.outside-month .event.owned {
  background-color: #a3d1a3;
}

.day-events {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.event {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6f2ff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.8rem;
  color: #0066cc;
  width: 100%;
  box-sizing: border-box;
  min-width: 100px;
  flex-direction: column;
}

.event.all-day {
  background-color: #cce5ff;
}

.event.owned {
  background-color: #e6ffe6;
}

.event-name {
  white-space: nowrap;
  margin-bottom: 4px;
}

.event-time {
  flex-shrink: 0;
  margin-right: 2px;
}

.event-duration {
  margin-left: 2px;
  flex-shrink: 0;
}

.today {
  border: 2px solid #0066cc;
}

/* Removed duplicated modal styles - using utility classes instead */

.modal-title {
  color: #61dafb;
  margin-top: 0;
  text-align: center;
  font-size: 30px;
}

.modal p {
  margin-bottom: 1rem;
}

/* Using flex-center utility instead of custom modal-actions */
