.open-house-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.thank-you-message {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.thank-you-message h2 {
  color: #007bff;
  margin-bottom: 15px;
}

.thank-you-message p {
  color: #495057;
  line-height: 1.6;
}

/* Removed form-group styles as they're in utilities.css */

.react-input-mask {
  display: block;
  width: 100%;
}

button {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Removed field-error styles in favor of error-message utility */

.open-house-header {
  padding-top: 80px;
}
