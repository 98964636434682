.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 120px auto 0; /* Updated top margin to 80px */
  padding: 10px;
  background-color: #1e1e1e;
  color: #ffffff;
}

.booking-iframe-container {
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.booking-iframe {
  width: 100%;
  border: none;
  position: relative;
  z-index: 1;
}

/* Responsive design */
@media (max-width: 768px) {
  .booking-iframe {
    height: 660px;
  }
}

@media (max-width: 480px) {
  .booking-iframe {
    height: 560px;
  }
}

/* The following draws a box over the acuity iframe in order to hide Acuity's Login link.
   I do this because our website has its own Login link, and I don't want to confuse people.
 */
.acuity-login-obscurer {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 115px;
  height: 85px;
  background-color: #414141;
  pointer-events: none;
}

.acuity-login-obscurer::after {
  content: '';
  position: absolute;
  left: 0;
  top: 32px;
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}
