.carousel-wrapper {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
}

/* Main image container styles */
.main-image-container {
  position: relative;
  width: 100%;
  height: 500px;
  min-height: 440px;
  margin-bottom: 2px;
  background: black;
  overflow: hidden;
}

.main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 1;
  transition: opacity 0.45s ease-in-out;
  will-change: opacity;
}

/* Fade animations */
.main-image.fade-in {
  animation: fadeIn 0.45s ease-in-out forwards;
}

.main-image.fade-out {
  animation: fadeOut 0.45s ease-in-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* Navigation arrows */
.nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.nav-arrow:hover {
  opacity: 1;
  background-color: rgba(128, 128, 128, 0.5);
}

.nav-arrow.prev {
  left: 20px; /* Adjusted to be visible */
}

.nav-arrow.next {
  right: 20px; /* Adjusted to be visible */
}

/* Thumbnails styles */
.thumbnails-container {
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 8px 0;
  margin-top: 0;
  overflow: hidden;
}

.thumbnails-wrapper {
  display: flex;
  gap: 4px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1800px;
  transition: transform 0.3s ease-out;
}

.thumbnail {
  flex: 0 0 140px;
  width: 140px;
  height: 96px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.5;
  border: 2px solid transparent;
  filter: brightness(0.7);
  transition: all 0.3s ease;
}

.thumbnail:hover {
  opacity: 0.7;
  filter: brightness(0.85);
}

.thumbnail.active {
  opacity: 1;
  border-color: #fff;
  filter: brightness(1);
}

/* Loading state */
.carousel-loading {
  width: 100%;
  height: 495px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  background: black;
}

/* Responsive styles */
@media (max-width: 768px) {
  .main-image-container {
    height: 385px;
    min-height: 330px;
  }

  .nav-arrow.prev {
    left: 10px;
  }

  .nav-arrow.next {
    right: 10px;
  }

  .thumbnails-wrapper {
    max-width: 100vw;
  }

  .thumbnail {
    flex: 0 0 100px;
    width: 100px;
    height: 68px;
  }
}
