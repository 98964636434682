/* Inquiries.css */
.inquiries-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.inquiries-container h1 {
  text-align: center;
  padding-top: 80px;
}

.inquiries-container .thank-you-message {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #333333;
}

.inquiries-container .thank-you-message h2 {
  color: #007bff;
}

.inquiries-container textarea {
  resize: vertical;
  min-height: 100px;
}

.inquiries-container button {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.inquiries-container button:hover {
  background-color: #0056b3;
}

.inquiries-container button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Removed duplicated styles that are in utilities.css:
- form-group styles
- field-error styles (replaced with error-message utility)
- input and label styles
*/
