/* Login.css */

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 40px); /* viewport height minus header height */
  box-sizing: border-box;
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  background-color: #282c34;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
}

.login-container h1 {
  color: #61dafb;
  text-align: center;
  font-family: 'Verdana', sans-serif;
  font-size: 30px;
  padding: 0 0 15px 0;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.forgot-password {
  text-align: center;
  margin-top: 15px;
}

.forgot-password button {
  background: none;
  border: none;
  color: #61dafb;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
  width: auto;
}

.forgot-password button:hover {
  color: #4fa8d5;
  background: none;
}

/* Removed duplicated styles that are in utilities.css:
- error-message styles
- modal styles
- form-group styles
- input styles
- button styles
*/
