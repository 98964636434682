/* client/src/components/Home.css */
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

.home-container {
  text-align: center;
  padding: 0;
  background-color: black;
  min-height: 100vh;
  color: white;
  width: 100%;
}

/* Logo styles */
.logo-container {
  padding-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.studio-logo {
  width: 600px;
  max-width: 95%; /* Ensures logo doesn't overflow on narrow screens */
  height: auto; /* Maintains aspect ratio */
}

h1 {
  font-family: 'Allura', cursive;
  font-size: 5.5rem;
  margin: 2rem 0;
  font-weight: 400;
  transform: scaleX(0.8);
  transform-origin: center center;
  padding-top: 3rem;
}

p {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 1.5rem auto;
  color: rgba(255, 255, 255, 0.9);
}

/* Rental rates section with minimal top margin */
.rates-container {
  max-width: 600px;
  margin: 1rem auto;
  text-align: center;
  padding: 1rem;
  background-color: transparent;
  border-radius: 8px;
}

.rates-container h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.rates-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rates-container li {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
}

.rates-container p {
  margin-top: 2rem;
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

.inquiries-link {
  text-align: center;
  margin: 3rem 0;
}

.inquiries-link a {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.inquiries-link a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  h1 {
    font-size: 4rem;
    padding-top: 2rem;
  }

  p {
    font-size: 1.1rem;
    padding: 0 1rem;
  }

  .rates-container {
    margin: 0.5rem 1rem;
  }
}
