.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 40px);
  padding-top: 120px;
  box-sizing: border-box;
}

.user-profile h2 {
  color: #61dafb;
  text-align: center;
  font-family: 'Verdana', sans-serif;
  font-size: 36px; /* Reduced font size */
  padding: 0 0 30px 0;
}

.profile-container {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  background-color: #282c34;
  border-radius: 8px;
  color: #e0e0e0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profile-info div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile-info label {
  color: #e0e0e0;
  font-weight: bold;
}

.profile-info span {
  color: #ffffff;
  padding: 10px;
  background-color: #3a3a3a;
  border-radius: 4px;
  border: 1px solid #4a4a4a;
}

.user-profile button {
  width: 100%;
  padding: 10px;
  background-color: #61dafb;
  color: #282c34;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.user-profile button:hover {
  background-color: #4fa8d5;
}

.user-profile button:nth-child(2) { /* Add margin to the second button */
  margin-left: 10px;
}

/* Modal form specific styles */
.modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal input[type="tel"] {
  font-family: monospace;
}

/* Removed duplicated modal, error, and form styles in favor of utilities */
