/* ResetPassword.css */

.reset-password-container {
  width: 665px;
  height: 545px;
  margin: 100px auto 20px auto;
  padding: 20px;
  background-color: #282c34;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
}

.reset-password-header {
  color: #61dafb;
  text-align: center;
  font-family: 'Verdana', sans-serif;
  font-size: 30px;
}

.reset-password-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.password-rules {
  margin-top: 20px;
  list-style-type: disc;
  margin-left: 20px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #61dafb;
  color: #282c34;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #4fa8d5;
}

.reset-password-container h2 {
  margin-top: 0;
}

/* Removed duplicated styles that are in utilities.css:
- form-group styles
- error-message styles
- sds-logo styles
*/
