/* Global Utility Classes */

/* Form Utilities */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #c0c0c0;
}

.form-group input,
.form-group textarea,
.form-group .react-input-mask input {
  width: 100%;
  padding: 10px;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  background-color: #3a3a3a;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

/* Error Message Utility */
.error-message {
  color: #ff6b6b;
  margin-top: 10px;
  text-align: center;
}

/* Modal Utilities */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #282c34;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  color: #e0e0e0;
}

.modal h2 {
  color: #61dafb;
  margin-top: 0;
  text-align: center;
  font-size: 30px;
}

/* Logo Utility */
.sds-logo {
  text-align: center;
  margin-bottom: 20px;
}

.sds-logo img {
  max-width: 300px;
  height: auto;
}

/* Container Utilities */
.page-container {
  max-width: 600px;
  margin: 100px auto 20px;
  padding: 20px;
  background-color: #282c34;
  border-radius: 8px;
  color: #e0e0e0;
}

/* Button Utilities */
.primary-button {
  width: 100%;
  padding: 10px;
  background-color: #61dafb;
  color: #282c34;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: #4fa8d5;
}

.primary-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Flex Utilities */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* Spacing Utilities */
.mb-1 { margin-bottom: 0.5rem; }
.mb-2 { margin-bottom: 1rem; }
.mb-3 { margin-bottom: 1.5rem; }
.mb-4 { margin-bottom: 2rem; }

.mt-1 { margin-top: 0.5rem; }
.mt-2 { margin-top: 1rem; }
.mt-3 { margin-top: 1.5rem; }
.mt-4 { margin-top: 2rem; }

/* Text Utilities */
.text-center { text-align: center; }
.text-primary { color: #61dafb; }
.text-error { color: #ff6b6b; }
.text-light { color: #e0e0e0; }
